<template>
  <div class="cbox JobContainer">
    <tchtree v-model="class_id" @change="getData"></tchtree>
    <el-button size="small" @click="downXls" icon="el-icon-download">下载表格</el-button>
    学制：
    <el-select v-model="xuezhi" size="small">
      <el-option label="五年制高职" value="五年"></el-option>
      <el-option label="三年制中职" value="三年"></el-option>
    </el-select>
    <el-button size="small" @click="downJiuye" icon="el-icon-download">下载就业统计表</el-button>
    <el-button size="small" @click="downFenlei" icon="el-icon-download">下载就业分类表</el-button>
    <el-button size="small" @click="downMingdan" icon="el-icon-download">下载毕业生名册</el-button>

    <!-- <table class="dttable">
      <tr v-for="(item,i) in nArr" :key="i">
        
        <td>{{ item.upname }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.data.duikoulv }}</td>
      </tr>
    </table> -->
    <iframe v-if="iframeshow" style="width:100%;height:100%;border:0" :src="url"></iframe>

  </div>
</template>
<script>
import tchtree from '../com/tchTree.vue'
import { exportWord } from "@/utils/exportword";
export default {
  components: {
    tchtree
  },
  name: 'deptRole',
  data() {
    return {
      iframeshow: false,
      code: '',
      class_id: "",
      url: "//sx.qushixi.org.cn/91job/#/bzr_wstj?xxdm=",
      year: new Date().getFullYear(),
      xuezhi: "",
      classesInfo: null,
      nArr:[]
    }
  },
  mounted() {
    this.getConfig()


  },
  methods: {
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", {
        keyword: "91job_code",
        ktype: "ktype"
      }).then(res => {
        for (let item of res.data.data) {
          this.code = item.kvalue

        }
        if (!this.code) {
          this.$message.error("未设置91job学校代码")
        } else {
          // 获取其他信息
          this.$http.post("/api/get_tch_cls_data", { biye: "" }).then(res => {
            this.class_id = res.data.bj_ids
            this.classesInfo = res.data.bj_list
            this.getInfo()
          })




        }
      })
    },
    getData(e) {

      console.log(this.class_id)
      this.getInfo()

    },
    getInfo() {
      this.iframeshow = false
      this.url = "//sx.qushixi.org.cn/91job/#/bzr_wstj?xxdm=" + this.code + "&year=" + this.year + "&class_ids=" + this.class_id
      setTimeout(() => {
        this.iframeshow = true
      }, 300);
    },
    downXls() {
      this.$http.post("/api/down_bzrws_xls", { xymc: this.code, year: this.year, class_id: this.class_id }).then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank")
        }
      })
    },
    downJiuye() {
      if (!this.xuezhi) {
        this.$message.error("请选择学制")
        return
      }
      
      // let clscount = 0,nArr=[];
      // for (let item of this.classesInfo) {

      //   if (item.name.indexOf("518") == 0 && !isNaN(item.name)) {
      //     clscount++
      //     if (clscount>=21&&clscount<=30) {
      //       (item => {
      //         this.$http.post("/api/get_bytjb_data", { xymc: this.code, year: this.year, xuezhi: this.xuezhi, pmid: window.pmid, class_name: "'" + item.name + "'" }).then(res => {
      //           exportWord('./docs/毕业生就业统计表模板.docx', res.data, item.name +"-"+item.upname+ '--毕业生就业统计表')
      //           item.data = res.data
      //           console.log(item)
      //           nArr.push(item)
      //         })
      //       })(item)

      //     }
      //   }

      // }

      // setTimeout(()=>{
      //   this.nArr = nArr
      // },2000)


      this.$http.post("/api/get_bytjb_data",{xymc:this.code,year:this.year,xuezhi:this.xuezhi,pmid:window.pmid,class_name:this.classesInfo.map(e=>{return "'"+ e.name+"'"}).join(",")}).then(res=>{
        exportWord('./docs/毕业生就业统计表模板.docx', res.data , '毕业生就业统计表总表')
      })

      // this.$http.post("/api/get_bytjb_data",{xymc:this.code,year:this.year,xuezhi:this.xuezhi,pmid:window.pmid,class_name:"'518111','518112','518121','518122','518123','518131','518141','518142'"}).then(res=>{
      //   exportWord('./docs/毕业生就业统计表模板.docx', res.data , '土木2018级毕业生就业统计表')
      // })
      // this.$http.post("/api/get_bytjb_data",{xymc:this.code,year:this.year,xuezhi:this.xuezhi,pmid:window.pmid,class_name:"'518321','518322','518331','518332','518341'"}).then(res=>{
      //   exportWord('./docs/毕业生就业统计表模板.docx', res.data , '环境艺术2018级毕业生就业统计表')
      // })

      // this.$http.post("/api/get_bytjb_data",{xymc:this.code,year:this.year,xuezhi:this.xuezhi,pmid:window.pmid,class_name:"'518211','518212','518213','518214','518241','518251','518271'"}).then(res=>{
      //   exportWord('./docs/毕业生就业统计表模板.docx', res.data , '电气2018级毕业生就业统计表')
      // })

      // this.$http.post("/api/get_bytjb_data",{xymc:this.code,year:this.year,xuezhi:this.xuezhi,pmid:window.pmid,class_name:"'518411','518421','518422','518451','518452','518453','518461'"}).then(res=>{
      //   exportWord('./docs/毕业生就业统计表模板.docx', res.data , '计管2018级毕业生就业统计表')
      // })
    },
    downFenlei() {
      this.$http.post("/api/down_fenleibiaoAll", { xymc: this.code, year: this.year, xuezhi: this.xuezhi, pmid: window.pmid }).then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank")
        }
      })
    },
    downMingdan() {
      this.$alert('名册生成较慢，请稍等...', '提示', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
      let class_list = []
      this.$http.post("/api/down_91job_allmingce", { xymc: this.code, year: this.year, xuezhi: this.xuezhi, pmid: window.pmid, class_list: [] }).then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank")
        }
      })
    }
  }
}
</script>
<style type="text/less"></style>
